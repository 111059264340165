import { ActionTypes } from '../constants';
import { takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import { history, login } from 'shared/routes/urlLocations';
import utils from '../utils';
import Api from '../api';

function* signOut() {
  try {
    let accessToken = sessionStorage.getItem('access_token');
    yield put({ type: ActionTypes.SHOW_SPINNER });
    yield call(Api.Auth.logout, `${utils.config.apiUrl}/token/invalidate`, accessToken);
    yield call(setAuthorizationToken);
    yield delay(1000);
    yield call(history.push, login);
    yield put({ type: ActionTypes.HIDE_SPINNER });
  } catch ({ message }) {
    yield put({
      type: ActionTypes.USER_LOGOUT_REQUEST_FAILURE,
      message
    });
  } finally {
    yield call(setAuthorizationToken);
    yield put({ type: ActionTypes.USER_LOGOUT_REQUEST_FINISHED });
  }
}

export default function* watchSignOut() {
  yield takeLatest(ActionTypes.USER_LOGOUT_REQUEST, signOut);
}


