import React from 'react';
import * as pathname from 'shared/routes/urlLocations';
import Icons from 'shared/components/Icons';

const projectsAll = {
  linkTo: pathname.projects,
  IconComponent: Icons.allProject,
  name: 'All Projects',
  permissionName: 'TAB_ALL_PROJECTS'
};

const dashboard = {
  linkTo: pathname.dashboard,
  name: 'Dashboard',
  permissionName: 'DASHBOARD',
  IconComponent: Icons.dashboard
};

export default {
  admin: [
    dashboard,
    projectsAll,
    {
      subMenu: true,
      IconComponent: Icons.user,
      name: 'User Handling',
      inPath: pathname.userHandling,
      items: [
        {
          linkTo: pathname.userHandlingAdminUsers,
          name: 'Admin Users',
          permissionName: 'TAB_ADMIN_USERS'
        },
        {
          linkTo: pathname.userHandlingAdvisors,
          name: 'Advisors',
          permissionName: 'TAB_ADVISOR'
        },
        {
          linkTo: pathname.userHandlingInvestors,
          name: 'Investors',
          permissionName: 'TAB_INVESTORS'
        },
        {
          linkTo: pathname.userHandlingIssuers,
          name: 'Issuers',
          permissionName: 'TAB_ISSUERS'
        },
        {
          linkTo: pathname.userHandlingStrategicPartners,
          name: 'Strategic Partners',
          permissionName: 'TAB_STRATEGIC_PARTNER'
        }
      ]
    }
  ],
  super_admin: [
    dashboard,
    projectsAll,
    {
      subMenu: true,
      IconComponent: Icons.user,
      name: 'User Handling',
      inPath: pathname.userHandling,
      items: [
        {
          linkTo: pathname.userHandlingAdminUsers,
          name: 'Admin Users',
          permissionName: 'TAB_ADMIN_USERS'
        },
        {
          linkTo: pathname.userHandlingAdvisors,
          name: 'Advisors',
          permissionName: 'TAB_ADVISOR'
        },
        {
          linkTo: pathname.userHandlingInvestors,
          name: 'Investors',
          permissionName: 'TAB_INVESTORS'
        },
        {
          linkTo: pathname.userHandlingIssuers,
          name: 'Issuers',
          permissionName: 'TAB_ISSUERS'
        },
        {
          linkTo: pathname.userHandlingStrategicPartners,
          name: 'Strategic Partners',
          permissionName: 'TAB_STRATEGIC_PARTNER'
        },
        {
          linkTo: pathname.userHandlingSPInvestors,
          name: 'SP Investors',
          permissionName: 'TAB_STRATEGIC_PARTNER'
        },
        {
          linkTo: pathname.userHandlingSPIssuers,
          name: 'SP Issuers',
          permissionName: 'TAB_STRATEGIC_PARTNER'
        }
      ]
    },
    {
      linkTo: pathname.settings,
      IconComponent: Icons.gear,
      name: 'Settings',
      permissionName: 'TAB_ADMIN_SETTINGS'
    }
  ]
};
