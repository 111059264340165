import React from 'react';
import { LocaleProvider } from 'antd';
import { Route, Router, Switch } from 'react-router';
import asyncComponent from 'shared/utils/asyncComponent';
import enUS from 'antd/lib/locale-provider/en_US';
import * as pathname from 'shared/routes/urlLocations';
import PrivateRoute from '../hoc/privateRoute';
import { history } from 'shared/routes/urlLocations';

const AsyncUnauthorizedLayout = asyncComponent(() => import('../layouts/UnauthorizedLayout'));
const AsyncMainLayout = asyncComponent(() => import('../layouts/MainLayout'));
const AsyncLoginPage = asyncComponent(() => import('../containers/LoginPage'));
const AsyncHomePage = asyncComponent(() => import('../containers/HomePage'));
const AsyncRestorePasswordPage = asyncComponent(() => import('../containers/RestorePasswordPage'));
const AsyncConfirmEmailPage = asyncComponent(() => import('../containers/ConfirmEmailPage'));
const AsyncSettingsPage = asyncComponent(() => import('../containers/SettingsPage'));
const AsyncAdminUsersListPage = asyncComponent(() => import('../containers/AdminUsersPage/AdminUsersListPage'));
const AsyncAdminUsersCreatePage = asyncComponent(() => import('../containers/AdminUsersPage/AdminUsersCreatePage'));
const AsyncStrategicPartnersListPage = asyncComponent(() => import('../containers/StrategicPartnersPage/StrategicPartnersListPage'));
const AsyncStrategicPartnersCreatePage = asyncComponent(() => import('../containers/StrategicPartnersPage/StrategicPartnersCreatePage'));
const AsyncProjectsPage = asyncComponent(() => import('../containers/ProjectsPage'));
const AsyncIssuersListPage = asyncComponent(() => import('../containers/IssuersPage/IssuersList'));
const AsyncInvestorsListPage = asyncComponent(() => import('../containers/InvestorsPage/InvestorsList'));
const AsyncAdvisorsListPage = asyncComponent(() => import('../containers/AdvisorsPage/AdvisorsList'));
const AsyncNotFoundPage = asyncComponent(() => import('../containers/NotFoundPage'));
const AsyncIssuerProfilePage = asyncComponent(() => import('../containers/ProfilePage'));
const AsyncProjectDetailsPage = asyncComponent(() => import('../containers/ProjectDetailsPage'));
const NotificationPage = asyncComponent(() => import('../containers/NotificationsPage'));
const KYCUserStatusPage = asyncComponent(() => import('../containers/KYCUserStatusPage'));
const AsyncProjectRejectionsHistory = asyncComponent(() => import('../containers/ProjectRejectionsHistoryPage'));
const AsyncUserRejectionsHistory = asyncComponent(() => import('../containers/UserRejectionsHistoryPage'));
const AsyncSPInvestorsListPage = asyncComponent(() => import('../containers/SPInvestorsPage'));
const AsyncSPIssuersListPage = asyncComponent(() => import('../containers/SPIssuersPage'));
const AsyncErrorLostConnection = asyncComponent(() => import('shared/components/ErrorLostConnection'));
const AsyncStrategicPartnersSubscriptionsPage = asyncComponent(() => import('../containers/StrategicPartnersPage/StrategicPartnersSubscriptionsPage'));
const AsyncDashboardPage = asyncComponent(() => import('../containers/DashboardPage'));

export default () => (
  <Router history={history}>
    <LocaleProvider locale={enUS}>
      <Switch>
        <Route
            path={pathname.login}
            exact
            render={() => (
            <AsyncUnauthorizedLayout>
              <AsyncLoginPage />
            </AsyncUnauthorizedLayout>
            )}
        />
        <Route
            path={pathname.restorePasswordToken}
            render={() => (
            <AsyncUnauthorizedLayout>
              <AsyncRestorePasswordPage />
            </AsyncUnauthorizedLayout>
            )}
        />
        <Route
            path={pathname.restorePassword}
            render={() => (
            <AsyncUnauthorizedLayout>
              <AsyncRestorePasswordPage />
            </AsyncUnauthorizedLayout>
            )}
        />
        <Route
            path={pathname.confirmEmailToken}
            render={() => (
            <AsyncUnauthorizedLayout>
              <AsyncConfirmEmailPage />
            </AsyncUnauthorizedLayout>
            )}
        />

        <Route exact
          path={pathname.lostConnection}
          render={() => <AsyncErrorLostConnection />} />


        <AsyncMainLayout>
          <Switch>
            <PrivateRoute
                path={pathname.settings}
                exact
                component={() => (
                  <AsyncSettingsPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.homePage}
                component={() => (
                  <AsyncHomePage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingAdminUsers}
                component={() => (
                  <AsyncAdminUsersListPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.adminUsersCreate}
                component={() => (
                  <AsyncAdminUsersCreatePage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingStrategicPartners}
                component={() => (
                  <AsyncStrategicPartnersListPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.strategicPartnersCreate}
                component={() => (
                  <AsyncStrategicPartnersCreatePage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.strategicPartnersIdSubscriptions}
                component={() => (
                  <AsyncStrategicPartnersSubscriptionsPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingIssuers}
                component={() => (
                  <AsyncIssuersListPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingInvestors}
                component={() => (
                  <AsyncInvestorsListPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingAdvisors}
                component={() => (
                  <AsyncAdvisorsListPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingSPInvestors}
                component={() => (
                  <AsyncSPInvestorsListPage />
                )}
            />
            <PrivateRoute
                exact
                path={pathname.userHandlingSPIssuers}
                component={() => (
                  <AsyncSPIssuersListPage />
                )}
            />
            <PrivateRoute
                path={pathname.stubPage}
                exact
                component={() => (
                  <AsyncProjectsPage />
                )}
            />
            <PrivateRoute
                path={pathname.projects}
                exact
                component={() => (
                  <AsyncProjectsPage />
                )}
            />
            <PrivateRoute
                path={`${pathname.projectDetails}/:id`}
                exact
                component={() => (
                  <AsyncProjectDetailsPage />
                )}
            />
            <PrivateRoute
                path={`${pathname.projectRejectionsHistory}/:id`}
                exact
                component={() => (
                  <AsyncProjectRejectionsHistory />
                )}
            />
            <PrivateRoute
              path={`${pathname.userRejectionsHistory}/:id`}
              exact
              component={() => (
                <AsyncUserRejectionsHistory />
              )}
            />
            <PrivateRoute
                path={pathname.userProfile}
                exact
                component={() => (
                  <AsyncIssuerProfilePage />
                )}
            />
            <PrivateRoute
                path={pathname.notifications}
                exact
                component={() => (
                  <NotificationPage />
                )}
            />
            <PrivateRoute
                path={pathname.dashboard}
                exact
                component={() => (
                  <AsyncDashboardPage />
                )}
            />
            <PrivateRoute
                path={pathname.KYCUserIdStatus}
                exact
                component={() => (
                  <KYCUserStatusPage />
                )}
            />

            <PrivateRoute
              component={() => (
                <AsyncNotFoundPage />
              )}
            />
          </Switch>
        </AsyncMainLayout>

      </Switch>
    </LocaleProvider>
  </Router>
);
