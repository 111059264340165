import ActiveRecord from "./ActiveRecord";
import NetworkLayer from "../utils/networkLayer";

class Project extends ActiveRecord {
  constructor() {
    super();
    this._logPromise = Promise.resolve();
  }

  getProjectDetails(url, { id, params }) {
    const request = { params };
    return NetworkLayer.getJson(`${url}/${id}`, request)
      .then(response => response)
      .catch(e => e);
  }

  getProjects(url, params) {
    const request = { params };
    return NetworkLayer.getJson(url, request)
      .then(response => response)
      .catch(e => e);
  }

  approveProject(url, { id }) {
    return NetworkLayer.putJson(`${url}/${id}/approve`)
      .then(response => response)
      .catch(e => e);
  }

  rejectProject(url, { id, data }) {
    const request = { data };
    return NetworkLayer.putJson(`${url}/${id}/reject`, request)
      .then(response => response)
      .catch(e => e);
  }

  editProjectEndDate(url, { id, date }) {
    const request = { data: { endDate: date } };
    return NetworkLayer.putJson(`${url}/${id}/edit-endDate`, request)
      .then(response => response)
      .catch(e => e);
  }

  getAllProjectData(url, { id, projection }) {
    return NetworkLayer.getJson(`${url}/${id}`, { params: { projection } })
      .then(response => response)
      .catch(e => e);
  }

  getProjectRejectionsHistory(url, { id, projection }) {
    return NetworkLayer.getJson(`${url}/${id}`, { params: { projection } })
      .then(response => response)
      .catch(e => e);
  }

  toggleFeaturedStatus(url, { id }) {
    return NetworkLayer.putJson(`${url}/${id}/featured/toggle`)
      .then(response => response)
      .catch(e => e);
  }


  fileCategories = url =>
    NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
}

export default Project;
