//      

import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';
import utils from '../utils';
import urlsConfig from 'shared/utils/config';
import defaultRedirect from '../utils/defaultReferer';

class User extends ActiveRecord {
  _isAuthenticated = false;

  get isAuthenticated() {
    this._isAuthenticated = sessionStorage.getItem('isAuthenticated') || false;
    return this._isAuthenticated;
  }

  info = data => {
    if (Object.keys(data).length !== 0) {
      for (let arr of Object.entries(data)) {
        sessionStorage.setItem(...arr);
      }
      sessionStorage.setItem('isAuthenticated', true);
      NetworkLayer.authToken = `${data.token_type} ${data.access_token}`;
    }
  };

  referer = history => {
    const {
      location: { state }
    } = history;
    return defaultRedirect(state);
  };

  initiateUserInformation = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  adminUserCreate = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  adminUserStatusHandle = (url, { id, enabled }) => {
    return NetworkLayer.postJson(`${url}/${id}/enable?enable=${!enabled}`, {})
      .then(response => response)
      .catch(e => e);
  };

  userStatusHandle = (url, { id, enabled }) => {
    return NetworkLayer.postJson(`${url}/${id}/enable?enable=${!enabled}`, {})
      .then(response => response)
      .catch(error => {
        console.error('Error confirming user:', error);
        throw error;
      });
  };



  getSubdomains = () => {
    return NetworkLayer.getJson(`${utils.config.apiUserUrl}/users/subdomain`)
      .then(response => response)
      .catch(e => e);
  };

  strategicPartnerCreate = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  fetchUsersList = (url, params) => {
    return NetworkLayer.getJson(url, { params })
      .then(response => response)
      .catch(e => e);
  };

  changePassword = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  setUserKYCStatus = ({ id, reason, status }) => {
    const request = { data: { reason } };
    return NetworkLayer.postJson(`${utils.config.apiUserUrl}/users/${id}/kyc/${status}`, request)
      .then(response => response)
      .catch(e => e);
  };

  saveUserScreening = ({id}) => {
    return NetworkLayer.postJson(`${utils.config.apiUserUrl}/users/${id}/kyc/check`)
      .then(response => response)
      .catch(e => e);
  };

  KYCUserStatus = (url, params) => {
    return NetworkLayer.getJson(url, { params })
      .then(response => response)
      .catch(e => e);
  };

  getWalletBalance = (url, params) => {
    return NetworkLayer.getJson(url, { params })
      .then(response => response)
      .catch(e => e);
  };

  getUserPlatformCommission = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  putUserPlatformCommission = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  getProjectPublishingPrice = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  setProjectPublishingPrice = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  getSubscriptionPrice = (url, { subDomain = null }) => {
    return NetworkLayer.getJson(`${url}${subDomain ? `/sp?subDomain=${subDomain}` : ''}`)
      .then(response => response)
      .catch(e => e);
  };

  setSubscriptionPrice = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  contactUsRequest = (url, { data }) => {
    return NetworkLayer.postJson(
      `${url}${sessionStorage.access_token ? '/users/me' : ''}/contact-us`,
      {
        data
      }
    )
      .then(response => response)
      .catch(e => e);
  };

  activateSpSubscription = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })     .then(response => response)
      .catch(e => e);
  };

  getSubscriptionHistory = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  getUserRejectionsHistory(url, { id, projectionName }) {
    return NetworkLayer.getJson(`${url}/${id}`, { params: { projectionName } })
      .then(response => response)
      .catch(e => e);
  }

  getSpPlatformConfig = subDomain => {
    return NetworkLayer.getJson(
      `${urlsConfig.apiPlatformConfigUrl}/white-label-product-config?subDomain=${subDomain}`
    )
      .then(response => response)
      .catch(e => e);
  };
}

export default User;
