import { ActionTypes } from "../constants";
import initialState from "./initialState";

export default (state = initialState.projects, { type, payload = {} }) => {
  switch (type) {
    case ActionTypes.FINISHED_PROJECTS_GET_REQUEST_SUCCESS:
      return { ...state, finished: payload };
    case ActionTypes.ONGOING_PROJECTS_GET_REQUEST_SUCCESS:
      return { ...state, ongoing: payload };
    case ActionTypes.PENDING_PROJECTS_GET_REQUEST_SUCCESS:
      return { ...state, pending: payload };
    case ActionTypes.REJECTED_PROJECTS_GET_REQUEST_SUCCESS:
      return { ...state, rejected: payload };
    case ActionTypes.PROJECT_DETAILS_GET_REQUEST_SUCCESS:
    case ActionTypes.PROJECT_DETAILS_CLEAR_REQUEST:
      return { ...state, projectDetails: payload };
    case ActionTypes.PROJECT_REJECTIONS_HISTORY_GET_REQUEST_SUCCESS:
      return { ...state, rejectionsHistory: payload };
    case ActionTypes.PROJECT_REJECTIONS_HISTORY_CLEAR:
      return { ...state, rejectionsHistory: {} };
    case ActionTypes.PROJECT_FEATURED_TOGGLE:
      return {
        ...state,
        allProjectData: {
          ...state.allProjectData,
          featured: !state.allProjectData.featured,
        },
      };
    default:
      return state;
  }
};
