import { ActionTypes } from "../constants";

export const getFinishedProjects = (
  page,
  filters,
  sort = { field: "publishedDate", order: "desc" }
) => ({
    type: ActionTypes.FINISHED_PROJECTS_GET_REQUEST,
    requestConfig: {
      projection: "approved",
      sort: `${sort.field},${sort.order}`,
      size: 10,
      page: (page && page.current && page.current - 1) || 0
    }
});

export const getOngoingProjects = (
  page,
  filters,
  sort = { field: "publishedDate", order: "desc" }
) => ({
  type: ActionTypes.ONGOING_PROJECTS_GET_REQUEST,
  requestConfig: {
    projection: "approved",
    sort: `${sort.field},${sort.order}`,
    size: 10,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const toggleProjectFeature = (id) => ({
  type: ActionTypes.PROJECT_FEATURED_TOGGLE,
  requestConfig: {
    id,
  },
});

export const getPendingProjects = (
  page,
  filters,
  sort = { field: "publishedDate", order: "desc" }
) => ({
  type: ActionTypes.PENDING_PROJECTS_GET_REQUEST,
  requestConfig: {
    sort: `${sort.field},${sort.order}`,
    projection: "pending",
    size: 10,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const getRejectedProjects = (
  page,
  filters,
  sort = { field: "publishedDate", order: "desc" }
) => ({
  type: ActionTypes.REJECTED_PROJECTS_GET_REQUEST,
  requestConfig: {
    projection: "rejected",
    sort: `${sort.field},${sort.order}`,
    size: 10,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const getProjectDetails = id => ({
  type: ActionTypes.PROJECT_DETAILS_GET_REQUEST,
  requestConfig: {
    id,
    params: {
      projection: "details"
    }
  }
});

export const clearProjectDetails = () => ({
  type: ActionTypes.PROJECT_DETAILS_CLEAR_REQUEST
});

export const approveProject = id => ({
  type: ActionTypes.PROJECT_APPROVE_REQUEST,
  requestConfig: {
    id
  }
});

export const rejectProject = (id, data) => ({
  type: ActionTypes.PROJECT_REJECT_REQUEST,
  requestConfig: {
    id,
    data
  }
});

export const editProjectEndDate = (id, date) => ({
  type: ActionTypes.PROJECT_EDIT_END_DATE_REQUEST,
  requestConfig: {
    id,
    date
  },
  onSuccessActions: {
    type: ActionTypes.PROJECT_DETAILS_GET_REQUEST,
    requestConfig: {
      id,
      params: {
        projection: "details"
      }
    }
  },
});


export const getAllProjectData = id => ({
  type: ActionTypes.PROJECT_ALL_DATA_GET_REQUEST,
  requestConfig: {
    id,
    projection: "all"
  }
});

export const clearAllProjectData = () => ({
  type: ActionTypes.PROJECT_ALL_DATA_CLEAR
});

export const getRejectionsHistory = id => ({
  type: ActionTypes.PROJECT_REJECTIONS_HISTORY_GET_REQUEST,
  requestConfig: {
    id,
    projection: "rejectionsHistory"
  }
});

export const clearRejectionsHistory = id => ({
  type: ActionTypes.PROJECT_REJECTIONS_HISTORY_CLEAR
});

