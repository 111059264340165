import { ActionTypes } from '../constants';
import initialState from './initialState';

export default (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.USER_INFORMATION_REQUEST:
    case ActionTypes.USER_INFORMATION_REQUEST_STARTED:
      return {
        ...state,
        isFetching: true
      };
    case ActionTypes.USER_INFORMATION_REQUEST_FAILURE:
    case ActionTypes.USER_INFORMATION_REQUEST_SUCCESS:
      return { ...state, info: action.message, isFetching: false };

    case ActionTypes.FETCH_LIST_ADMIN_USERS_REQUEST_SUCCESS:
      return { ...state, admin: { list: action.users } };

    case ActionTypes.KYC_USER_STATUS_SUCCESS:
      return { ...state, KYCStatus: action.payload };

    case ActionTypes.SET_CURRENT_PAGE:
          return { ...state, currentPage: action.payload };

    default:
      return state;
  }
};
