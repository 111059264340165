import { takeEvery } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { LoginConfig } from '../utils/config';
import networkLayer from '../utils/networkLayer';
import utils from '../utils';
import Api from '../api';
import getParameterByName from 'shared/utils/getParameterByName';
import { history } from 'shared/routes/urlLocations';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import notification from '../utils/notifications';
import { fetchUserInformation } from 'shared/actions/userDataActions';
import { pushNotificationsConnectAction } from '../actions/pushNotificationsActions';

const API_URL = utils.config.apiUrlAuth;
const user = Api.User;

function* login(action) {
  const authorizeCodedHeader = `Basic ${btoa(`${action.data.email}:${action.data.password}`)}`;
  const tokenCodedHeader = `Basic ${btoa(`${LoginConfig.CLIENT_ID}:${LoginConfig.CLIENT_SECRET}`)}`;
  yield put({ type: ActionTypes.SHOW_SPINNER });

  let { response, request, status } = yield call(
    networkLayer.postJson,
    `${API_URL}/oauth/authorize`,
    {
      headers: { ...LoginConfig.HEADERS, Authorization: authorizeCodedHeader },
      params: {...LoginConfig.PARAMS.auth, reCaptchaToken: window.sessionStorage.getItem("recaptchaToken")}
    }
  );
  if (request && request.status === 200) {
    const { responseURL } = request;
    const code = getParameterByName('code', responseURL);
    const token = yield call(networkLayer.postJson, `${API_URL}/oauth/token`, {
      headers: { ...LoginConfig.HEADERS, Authorization: tokenCodedHeader },
      params: { ...LoginConfig.PARAMS.token, code }
    });
    if (token.response && token.request.status === 200) {
      yield put({ type: ActionTypes.USER_LOGIN_SUCCESS });
      yield put(fetchUserInformation());
      yield call(notification, {
        type: 'success',
        data: 'Welcome to Finbursa!',
        className: 'ant-notification-success ant-notification-notice-description',
        status: 'success',
        notificationType: 'message'
      });

      yield user.info(token.response);
      const referer = user.referer(history).referer;
      history.push(referer.pathname);
      yield put(pushNotificationsConnectAction());
      yield put({ type: ActionTypes.HIDE_SPINNER });
    } else {
      yield put({ type: ActionTypes.USER_LOGIN_FAILURE });
      yield put({ type: ActionTypes.HIDE_SPINNER });
      yield call(notification, { type: 'error', data: token.response, status });
      console.error('Cannot get login token: ', token.response);
    }
  } else {
    yield put({ type: ActionTypes.USER_LOGIN_FAILURE });
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield call(notification, {
      type: 'error',
      data: response,
      status
    });
  }
}

export default function* watchLogin() {
  yield takeEvery(ActionTypes.USER_LOGIN_REQUEST, login);
}
