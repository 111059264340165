import moment from 'moment';
import THEMES from 'shared/constants/themes';
import { chartTypes, charts, filterTypes } from 'shared/constants/charts';

const getInitialChartState = chartType => {
  const { filters } = charts[chartType];

  const params = Object.keys(filters).reduce((acc, filterType) => {
    switch (filterType) {
      case filterTypes.TIMESLOT:
        return {
          ...acc,
          startDate: moment().subtract(1, 'months'),
          endDate: moment()
        };
      case filterTypes.AMOUNT:
        return {
          ...acc,
          size: 10
        };

      case filterTypes.TIMEPOINTS:
        return {
          ...acc,
          timePoint: 'YEAR'
        };

      case filterTypes.PRODUCT_CATEGORY:
        return {
          ...acc,
          productCategories: []
        };

      case filterTypes.REGION:
        return {
          ...acc,
          regions: []
        };

      case filterTypes.SP:
        return {
          ...acc,
          sp: null
        };

      default:
        return acc;
    }
  }, {});

  return {
    params,
    content: [],
    loading: false,
    error: false
  };
};

const getInitialChartsState = () => {
  return Object.keys(chartTypes).reduce(
    (acc, chartType) => ({
      ...acc,
      [chartType + '-small']: getInitialChartState(chartType),
      [chartType + '-full']: getInitialChartState(chartType)
    }),
    {}
  );
};

export default {
  dashboard: {
    structure: [],
    charts: getInitialChartsState(),
    tags: {
      loading: false,
      error: false,
      data: []
    }
  },
  user: {
    currentPage: '',
    isFetching: false,
    info: {
      theme: THEMES.BLACK
    },
    KYCStatus: '',
    admin: {
      list: {}
    }
  },
  usersLists: {
    adminUsers: {
      list: {
        page: {
          number: 0
        }
      }
    },

    issuers: {
      list: {
        page: {
          number: 0
        }
      }
    },
    userRejectionsHistory: {},
    investors: {
      list: {
        page: {
          number: 0
        }
      }
    },
    strategicPartners: {
      list: {
        page: {
          number: 0
        }
      }
    },
    advisors: {
      list: {}
    },
    SPInvestors: {
      list: {}
    },
    SPIssuers: {
      list: {}
    }
  },
  countries: [],
  subdomains: null,
  spAdminDetails: null,
  currencies: [],
  notifications: {
    unread: {}
  },
  projects: {
    finished: [],
    ongoing: [],
    pending: [],
    rejected: [],
    projectDetails: {},
    allProjectData: {},
    rejectionsHistory: {}
  },
  modals: {
    rejectProject: false,
    spModalToggle: {
      isOpen: false,
      selectedItemId: null
    },
    approveModalToggle: {
      approveModalIsOpen: false,
      approveSelectedItemId: null
    }
    // repayModalIsOpen: false
  },
  form: {
    rejectProject: {
      values: {}
    }
  },
  payments: {
    platformCommission: {
      percent: ''
    },
    wallet: {
      platformFeesBalance: [],
      platformPaymentsBalance: []
    },
    subscriptionHistory: {},
    subscriptions: [],
    publishingPrice: null
  }
};
