//      

import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';
import sharedUrlsConfig from 'shared/utils/config';

class Registration extends ActiveRecord {
  constructor() {
    super();
    this._logPromise = Promise.resolve();
  }

  userRegistration(url, data) {
    return NetworkLayer.postJson(url, data)
      .then(response => response)
      .catch(e => e);
  }

  confirmationEmail(url, data) {
    const request = { data };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  }

  checkConfirmToken(url) {
    return NetworkLayer.getJson(url, {})
      .then(response => response)
      .catch(e => e);
  }

  strategicPartnerApplicationRegistration(url, data) {
    return NetworkLayer.postJson(url, { ...data, reCaptchaToken: sessionStorage.getItem('recaptchaToken') })
      .then(response => response)
      .catch(e => e);
  }

  uploadSPPlatformConfigFile(fileType, file) {
    const formData = new FormData();
    formData.append('fileUrl', file);

    return NetworkLayer.postJson(`${sharedUrlsConfig.apiFilesUrl}/users?type=${fileType}`, {
      data: formData
    })
      .then(response => response)
      .catch(e => e);
  }
}

export default Registration;
