import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  CHECK_USED_TOKEN_REQUEST_STARTED: undefined,
  CHECK_USED_TOKEN_REQUEST: undefined,
  CHECK_USED_TOKEN_REQUEST_FAILURE: undefined,
  CHECK_USED_TOKEN_REQUEST_SUCCESS: undefined,
  CHECK_USED_TOKEN_REQUEST_FINISHED: undefined,

  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_STARTED: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_SUCCESS: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_FAILURE: undefined,
  CHECK_CONFIRM_EMAIL_TOKEN_REQUEST_FINISHED: undefined,

  RESTORE_PASSWORD_REQUEST: undefined,
  RESTORE_PASSWORD_REQUEST_STARTED: undefined,
  RESTORE_PASSWORD_REQUEST_SUCCESS: undefined,
  RESTORE_PASSWORD_REQUEST_FAILURE: undefined,
  RESTORE_PASSWORD_REQUEST_FINISHED: undefined,

  FORGOT_PASSWORD_REQUEST: undefined,
  FORGOT_PASSWORD_REQUEST_STARTED: undefined,
  FORGOT_PASSWORD_REQUEST_SUCCESS: undefined,
  FORGOT_PASSWORD_REQUEST_FAILURE: undefined,
  FORGOT_PASSWORD_REQUEST_FINISHED: undefined,
  FORGOT_PASSWORD_RESET_FORM: undefined,

  USER_LOGIN_REQUEST: undefined,
  USER_LOGIN_REQUEST_STARTED: undefined,
  USER_LOGIN_REQUEST_FINISHED: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,

  CHANGE_PASSWORD_STARTED: undefined,
  CHANGE_PASSWORD: undefined,
  CHANGE_PASSWORD_SUCCESS: undefined,
  CHANGE_PASSWORD_FAILURE: undefined,
  CHANGE_PASSWORD_FINISHED: undefined,

  UPLOAD_NOTIFICATIONS_STARTED: undefined,
  UPLOAD_NOTIFICATIONS: undefined,
  UPLOAD_NOTIFICATIONS_SUCCESS: undefined,
  UPLOAD_NOTIFICATIONS_FAILURE: undefined,
  UPLOAD_NOTIFICATIONS_FINISHED: undefined,

  MARK_NOTIFICATION_AS_READ_STARTED: undefined,
  MARK_NOTIFICATION_AS_READ: undefined,
  MARK_NOTIFICATION_AS_READ_SUCCESS: undefined,
  MARK_NOTIFICATION_AS_READ_FAILURE: undefined,
  MARK_NOTIFICATION_AS_READ_FINISHED: undefined,

  DELETE_NOTIFICATION_STARTED: undefined,
  DELETE_NOTIFICATION: undefined,
  DELETE_NOTIFICATION_SUCCESS: undefined,
  DELETE_NOTIFICATION_FAILURE: undefined,
  DELETE_NOTIFICATION_FINISHED: undefined,

  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_REQUEST_STARTED: undefined,
  USER_LOGOUT_REQUEST_FINISHED: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,

  REFRESH_TOKEN_REQUEST_STARTED: undefined,
  REFRESH_TOKEN_REQUEST: undefined,
  REFRESH_TOKEN_REQUEST_SUCCESS: undefined,
  REFRESH_TOKEN_REQUEST_FAILURE: undefined,
  REFRESH_TOKEN_REQUEST_FINISHED: undefined,

  CONFIRM_EMAIL_REQUEST_STARTED: undefined,
  CONFIRM_EMAIL_REQUEST: undefined,
  CONFIRM_EMAIL_REQUEST_SUCCESS: undefined,
  CONFIRM_EMAIL_REQUEST_FAILURE: undefined,
  CONFIRM_EMAIL_REQUEST_FINISHED: undefined,

  USER_INFORMATION_REQUEST_STARTED: undefined,
  USER_INFORMATION_REQUEST: undefined,
  USER_INFORMATION_REQUEST_SUCCESS: undefined,
  USER_INFORMATION_REQUEST_FAILURE: undefined,
  USER_INFORMATION_REQUEST_FINISHED: undefined,

  ADMIN_USERS_LIST_REQUEST_STARTED: undefined,
  ADMIN_USERS_LIST_REQUEST: undefined,
  ADMIN_USERS_LIST_REQUEST_SUCCESS: undefined,
  ADMIN_USERS_LIST_REQUEST_FAILURE: undefined,
  ADMIN_USERS_LIST_REQUEST_FINISHED: undefined,

  STRATEGIC_PARTNERS_LIST_REQUEST_STARTED: undefined,
  STRATEGIC_PARTNERS_LIST_REQUEST: undefined,
  STRATEGIC_PARTNERS_LIST_REQUEST_SUCCESS: undefined,
  STRATEGIC_PARTNERS_LIST_REQUEST_FAILURE: undefined,
  STRATEGIC_PARTNERS_LIST_REQUEST_FINISHED: undefined,

  ISSUERS_LIST_REQUEST_STARTED: undefined,
  ISSUERS_LIST_REQUEST: undefined,
  ISSUERS_LIST_REQUEST_SUCCESS: undefined,
  ISSUERS_LIST_REQUEST_FAILURE: undefined,
  ISSUERS_LIST_REQUEST_FINISHED: undefined,

  INVESTORS_LIST_REQUEST_STARTED: undefined,
  INVESTORS_LIST_REQUEST: undefined,
  INVESTORS_LIST_REQUEST_SUCCESS: undefined,
  INVESTORS_LIST_REQUEST_FAILURE: undefined,
  INVESTORS_LIST_REQUEST_FINISHED: undefined,

  ADVISORS_LIST_REQUEST_STARTED: undefined,
  ADVISORS_LIST_REQUEST: undefined,
  ADVISORS_LIST_REQUEST_SUCCESS: undefined,
  ADVISORS_LIST_REQUEST_FAILURE: undefined,
  ADVISORS_LIST_REQUEST_FINISHED: undefined,

  SP_INVESTORS_LIST_FETCH: undefined,
  SP_INVESTORS_LIST_FETCH_STARTED: undefined,
  SP_INVESTORS_LIST_FETCH_SUCCESS: undefined,
  SP_INVESTORS_LIST_FETCH_FAILURE: undefined,
  SP_INVESTORS_LIST_FETCH_FINISHED: undefined,

  SP_ISSUERS_LIST_FETCH: undefined,
  SP_ISSUERS_LIST_FETCH_STARTED: undefined,
  SP_ISSUERS_LIST_FETCH_SUCCESS: undefined,
  SP_ISSUERS_LIST_FETCH_FAILURE: undefined,
  SP_ISSUERS_LIST_FETCH_FINISHED: undefined,

  ADMIN_USER_CREATE: undefined,
  ADMIN_USER_CREATE_STARTED: undefined,
  ADMIN_USER_CREATE_SUCCESS: undefined,
  ADMIN_USER_CREATE_FAILURE: undefined,
  ADMIN_USER_CREATE_FINISHED: undefined,

  ADMIN_USER_SET_STATUS: undefined,
  ADMIN_USER_SET_STATUS_STARTED: undefined,
  ADMIN_USER_SET_STATUS_SUCCESS: undefined,
  ADMIN_USER_SET_STATUS_FAILURE: undefined,
  ADMIN_USER_SET_STATUS_FINISHED: undefined,

  USER_CONFIRM_STATUS: undefined,
  USER_CONFIRM_STATUS_STARTED: undefined,
  USER_CONFIRM_STATUS_SUCCESS: undefined,
  USER_CONFIRM_STATUS_FAILURE: undefined,
  USER_CONFIRM_STATUS_FINISHED: undefined,

  STRATEGIC_PARTNER: undefined,
  STRATEGIC_PARTNER_STARTED: undefined,
  STRATEGIC_PARTNER_SUCCESS: undefined,
  STRATEGIC_PARTNER_FAILURE: undefined,
  STRATEGIC_PARTNER_FINISHED: undefined,

  STRATEGIC_PARTNER_APPLICATION_REGISTRATION: undefined,
  STRATEGIC_PARTNER_APPLICATION_REGISTRATION_STARTED: undefined,
  STRATEGIC_PARTNER_APPLICATION_REGISTRATION_SUCCESS: undefined,
  STRATEGIC_PARTNER_APPLICATION_REGISTRATION_FAILURE: undefined,
  STRATEGIC_PARTNER_APPLICATION_REGISTRATION_FINISHED: undefined,

  PROJECT_DETAILS_GET_REQUEST: undefined,
  PROJECT_DETAILS_GET_REQUEST_STARTED: undefined,
  PROJECT_DETAILS_GET_REQUEST_SUCCESS: undefined,
  PROJECT_DETAILS_GET_REQUEST_FAILURE: undefined,
  PROJECT_DETAILS_GET_REQUEST_FINISHED: undefined,

  PROJECT_DETAILS_CLEAR_REQUEST: undefined,

  FINISHED_PROJECTS_GET_REQUEST: undefined,
  FINISHED_PROJECTS_GET_REQUEST_STARTED: undefined,
  FINISHED_PROJECTS_GET_REQUEST_SUCCESS: undefined,
  FINISHED_PROJECTS_GET_REQUEST_FAILURE: undefined,
  FINISHED_PROJECTS_GET_REQUEST_FINISHED: undefined,

  ONGOING_PROJECTS_GET_REQUEST: undefined,
  ONGOING_PROJECTS_GET_REQUEST_STARTED: undefined,
  ONGOING_PROJECTS_GET_REQUEST_SUCCESS: undefined,
  ONGOING_PROJECTS_GET_REQUEST_FAILURE: undefined,
  ONGOING_PROJECTS_GET_REQUEST_FINISHED: undefined,

  PENDING_PROJECTS_GET_REQUEST: undefined,
  PENDING_PROJECTS_GET_REQUEST_STARTED: undefined,
  PENDING_PROJECTS_GET_REQUEST_SUCCESS: undefined,
  PENDING_PROJECTS_GET_REQUEST_FAILURE: undefined,
  PENDING_PROJECTS_GET_REQUEST_FINISHED: undefined,

  REJECTED_PROJECTS_GET_REQUEST: undefined,
  REJECTED_PROJECTS_GET_REQUEST_STARTED: undefined,
  REJECTED_PROJECTS_GET_REQUEST_SUCCESS: undefined,
  REJECTED_PROJECTS_GET_REQUEST_FAILURE: undefined,
  REJECTED_PROJECTS_GET_REQUEST_FINISHED: undefined,

  PROJECT_APPROVE_REQUEST: undefined,
  PROJECT_APPROVE_REQUEST_STARTED: undefined,
  PROJECT_APPROVE_REQUEST_SUCCESS: undefined,
  PROJECT_APPROVE_REQUEST_FAILURE: undefined,
  PROJECT_APPROVE_REQUEST_FINISHED: undefined,

  PROJECT_FEATURED_TOGGLE: undefined,

  PROJECT_REJECT_REQUEST: undefined,
  PROJECT_REJECT_REQUEST_STARTED: undefined,
  PROJECT_REJECT_REQUEST_SUCCESS: undefined,
  PROJECT_REJECT_REQUEST_FAILURE: undefined,
  PROJECT_REJECT_REQUEST_FINISHED: undefined,

  PROJECT_ALL_DATA_GET_REQUEST: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_STARTED: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_SUCCESS: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_FAILURE: undefined,
  PROJECT_ALL_DATA_GET_REQUEST_FINISHED: undefined,

  PROJECT_ALL_DATA_CLEAR: undefined,

  PROJECT_REJECTIONS_HISTORY_GET_REQUEST: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_STARTED: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_SUCCESS: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_FAILURE: undefined,
  PROJECT_REJECTIONS_HISTORY_GET_REQUEST_FINISHED: undefined,

  PROJECT_REJECTIONS_HISTORY_CLEAR: undefined,

  MODAL_REJECT_PROJECT_TOGGLE: undefined,

  AUTH_TOKEN: undefined,

  GET_COUNTRIES_REQUEST: undefined,
  GET_COUNTRIES_SUCCESS: undefined,

  SHOW_SPINNER: undefined,
  HIDE_SPINNER: undefined,

  CONNECT_WEBSOCKET_NOTIFICATIONS: undefined,
  DISCONNECT_WEBSOCKET_NOTIFICATIONS: undefined,

  GET_UNREAD_NOTIFICATIONS_COUNT_STARTED: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE: undefined,
  GET_UNREAD_NOTIFICATIONS_COUNT_FINISHED: undefined,

  SET_USER_KYC_STATUS_STARTED: undefined,
  SET_USER_KYC_STATUS_SUCCESS: undefined,
  SET_USER_KYC_STATUS: undefined,
  SET_USER_KYC_STATUS_FAILURE: undefined,
  SET_USER_KYC_STATUS_FINISHED: undefined,

  KYC_USER_STATUS_STARTED: undefined,
  KYC_USER_STATUS_SUCCESS: undefined,
  KYC_USER_STATUS: undefined,
  KYC_USER_STATUS_FAILURE: undefined,
  KYC_USER_STATUS_FINISHED: undefined,

  ACTIVATE_SP_SUBSCRIPTION: undefined,

  EXPORT_USERS_TO_EXEL_SUCCESS: undefined,
  EXPORT_USERS_TO_EXEL: undefined,
  EXPORT_USERS_TO_EXEL_FAILURE: undefined,

  MODAL_TOGGLE: undefined,
  MODAL_APPROVE_TOGGLE: undefined,

  SCREEN_USER: undefined,
  SCREEN_USER_STARTED: undefined,
  SCREEN_USER_SUCCESS: undefined,
  SCREEN_USER_FAILURE: undefined,

  PROJECT_EDIT_END_DATE_REQUEST: undefined,
  PROJECT_EDIT_END_DATE_REQUEST_STARTED: undefined,
  PROJECT_EDIT_END_DATE_REQUEST_SUCCESS: undefined,
  PROJECT_EDIT_END_DATE_REQUEST_FAILURE: undefined,
  PROJECT_EDIT_END_DATE_REQUEST_FINISHED: undefined,

  GET_SUBSCRIPTION_HISTORY_STARTED: undefined,
  GET_SUBSCRIPTION_HISTORY_SUCCESS: undefined,
  GET_SUBSCRIPTION_HISTORY: undefined,
  GET_SUBSCRIPTION_HISTORY_FINISHED: undefined,
  GET_SUBSCRIPTION_HISTORY_FAILURE: undefined,

  SET_CURRENT_PAGE: undefined,

  USER_REJECTIONS_HISTORY_GET_REQUEST: undefined,
  USER_REJECTIONS_HISTORY_GET_REQUEST_SUCCESS: undefined,
  USER_REJECTIONS_HISTORY_GET_REQUEST_STARTED: undefined,
  USER_REJECTIONS_HISTORY_GET_REQUEST_FAILURE: undefined,
  USER_REJECTIONS_HISTORY_GET_REQUEST_FINISHED: undefined,
});
